<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="7"></user-left>
        </div>
        <div class="col-10">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 border-bottom col-12">
              <h6>实名认证</h6>
            </div>
            <div class="col-12 mb-4">
              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>真实姓名</span>
                </div>
                <div class="float-left">
                  <input
                    v-model="trueName"
                    class="form-control mr-sm-2 width-300"
                    maxlength="20"
                    type="text"
                  />
                </div>
              </div>
              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>身份证号</span>
                </div>
                <div class="float-left">
                  <input
                    v-model="idNumber"
                    class="form-control mr-sm-2 width-300"
                    maxlength="20"
                    type="text"
                  />
                </div>
              </div>
              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>上传证件</span>
                </div>
                <div class="float-left">
                  <div class="clearfix">
                    <div class="float-left">
                      <van-uploader
                        preview-size="188"
                        :max-count="1"
                        :max-size="6 * 1024 * 1024"
                        :after-read="afterReadFront"
                      >
                        <van-image v-if="idCardFront != ''" :src="idCardFront" width="300" height="190"></van-image>
                        <img
                          v-else
                          src="@/assets/img/cer_front.jpg"
                          class="width-300"
                        />
                      </van-uploader>
                      <div class="mt-1 text-center">身份证正面</div>
                    </div>
                    <div class="float-left">
                      <van-uploader
                        class="ml-3"
                        preview-size="188"
                        :max-count="1"
                        :max-size="6 * 1024 * 1024"
                        :after-read="afterReadBack"
                      >
                        <van-image v-if="idCardBack != ''" :src="idCardBack" width="300" height="190"></van-image>
                        <img
                          v-else
                          src="@/assets/img/cer_back.jpg"
                          class="width-300"
                        />
                      </van-uploader>
                      <div class="mt-1 text-center">身份证反面</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clearfix mt-3">
                <b-button
                  @click="applyCertification"
                  class="width-300"
                  variant="success"
                  style="margin-left: 116px"
                  >申请认证</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
export default {
  name: "certificationpersonal",
  data() {
    return {
      id: "",
      idCardFront: "", //身份证正面
      idCardBack: "", //身份证反面
      idNumber: "", //身份证号
      trueName: "", //真实姓名
    };
  },
  methods: {
    afterReadFront(file) {
      this.$http
        .upload(this.$api.user.upload, "uploadUserFiles", file)
        .then((res) => {
          if (res.code == 1) {
            this.idCardFront = res.result[0].fileUrl;
          }
        });
    },
    afterReadBack(file) {
      this.$http
        .upload(this.$api.user.upload, "uploadUserFiles", file)
        .then((res) => {
          if (res.code == 1) {
            this.idCardBack = res.result[0].fileUrl;
          }
        });
    },
    getCertification() {
      this.$http.post(this.$api.user.getCertification).then((res) => {
        if (res.code == 1) {
          this.id = res.result.id;
          this.idCardFront = res.result.idCardFront;
          this.idCardBack = res.result.idCardBack;
          this.idNumber = res.result.idNumber;
          this.trueName = res.result.trueName;
        }
      });
    },
    applyCertification() {
      if(this.trueName.trim() == '') {
        this.$model.toast("请填写真实姓名");
        return false;
      } else {
        if (!this.$test.trueName(this.trueName)) {
          this.tips = '真实姓名格式错误'
          return false
        }
        if (!this.$test.firstName(this.trueName.substr(0, 1))) {
          this.tips = '真实姓名姓氏有误'
          return false
        }
      }

      if(!this.$test.idCard(this.idNumber)) {
        this.$model.toast("请填写正确的身份证号码");
        return false;
      }

      if(this.idCardFront == '') {
        this.$model.toast("请上传身份证正面");
        return false;
      }

      if(this.idCardBack == '') {
        this.$model.toast("请上传身份证反面");
        return false;
      }
      this.$http
        .post(this.$api.user.applyCertification, {
          id: this.id,
          idCardFront: this.idCardFront,
          idCardBack: this.idCardBack,
          idNumber: this.idNumber,
          trueName: this.trueName,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$model.toast("认证成功");
          }
        });
    },
  },
  created() {
    this.getCertification();
  },
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");
</style>
